import "./Header.scss";
import { Carousel } from "react-bootstrap";
import photo1 from "../../assets/images/Photo1.jpg"
import photo2 from "../../assets/images/Photo2.jpg";
import photo3 from "../../assets/images/Photo3.jpg";
import photo4 from "../../assets/images/Photo4.jpg";
function Header(){
    return (
      <header>
        <Carousel className="carusel">
          <Carousel.Item>
            <img className="slider-img" src={photo1} alt="" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="slider-img" src={photo2} alt="" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="slider-img" src={photo3} alt="" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="slider-img" src={photo4} alt="" />
          </Carousel.Item>
        </Carousel>
      </header>
    );
}
export default Header;