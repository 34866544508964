import "./Services.scss";

import ServicesImage from "../../assets/images/Photo5.jpg";
import ServicesImage2 from "../../assets/images/Photo3.jpg";

import { t } from "i18next";
function Services() {
  return (
    <>
      <section id="services">
        <div className="container">
          <div className="service_title d-flex align-items-center justify-content-center flex-column">
            <div className="blur">
              <h2>{t("servicesTitle.title")}</h2>
              <p>{t("servicesTitle.title1")}</p>
            </div>
          </div>
          <div></div>
        </div>
      </section>
      <section>
        <div className="container d-flex flex-column justify-content-center align-items-center">
          <div className="service_subtitle d-flex align-items-center justify-content-center">
            <div>
              <img src={ServicesImage} alt="" />
            </div>
            <div>
              <p>{t("servicesTitle.title2")}</p>
            </div>
          </div>
          <div className="service_subtitle d-flex align-items-center justify-content-center">
            <div>
              <p>{t("servicesTitle.title3")}</p>
            </div>
            <div>
              <img className="services-image" src={ServicesImage2} alt="" />
            </div>
          </div>
         
        </div>
      </section>
    </>
  );
}
export default Services;
