import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import "./styles/Management.scss";
import { getAllManangement } from "../../../Redux/Features/productSlice";
import Director from "../../../assets/images/managerDir.jpg";
import CardManagement from "./CardManagement";
import { getManager } from "../../../Redux/Features/productSlice";
import { Context } from "../../../Context";

function Management() {
  const { change } = useContext(Context);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getManager());
  }, []);
  const elements = useSelector(getAllManangement);
  const [data, setData] = useState([]);
  function filterElements(item) {
    const filteredElements = elements.filter((category) => {
      return category.attributes.category === item;
    });
    if (filteredElements === "all") {
      setData(elements);
    } else {
      setData(filteredElements);
    }
  }
  const dataManagers = [
    {
      id: 1,
      name: [
        "Рузиев Расулжон Санатиллаевич",
        "Ruziyev Rasuljon Sanatillayevich",
        "Ruziyev Rasuljon Sanatillayevich",
      ],
      job: ["Директор", "Direktor", "Direktor"],
      phone: "тел. 237-92-72 ж.д. 4-92-72",
      fax: "256-50-18",
      email: "temiryol@gmail.com",
      image: Director,
    },
    {
      id: 2,
      name: [
        "Ибраев Чингис Валиханович",
        "Ibrayev Chingis Valixanovich",
        "Ibrayev Chingis Valixanovich",
      ],
      job: ["Главный инженер", "Chief Engineer", "Bosh muhandis"],
      phone: "Тел. 237-97-28 ж.д. 4-97-28",
      fax: "256-50-18",
      email: "temiryol@gmail.com",
      image: "",
    },
    {
      id: 3,
      name: [
        "Рахимов Илхом Анвархужаевич",
        "Raximov Ilxom Anvarxujaevich",
        "Raximov Ilxom Anvarxujaevich",
      ],
      job: [
        "Зам. директор по кадровым вопросам",
        "Deputy HR director",
        "Kadrlar bo’yicha direktor o’rinbosari",
      ],
      phone: " тел. 237-92-72 ж.д. 4-92-72",
      fax: "256-50-18",
      email: "temiryol@gmail.com",
      image: "",
    },
  ];

  return (
    <div>
      <div className="">
        {dataManagers?.slice(0, 3).map((el, index) => {
          return (
            <div key={index} className="d-flex">
              <div className="cardManager d-flex align-items-center justify-content-around">
                <div>
                  <h4>{el.name[change]}</h4>
                  <h4>{el.job[change]}</h4>
                  <h4>{el.reception}</h4>
                  <h4>{el.phone}</h4>
                  <h4>{el.email}</h4>
                </div>
                <div>
                  <img src={el.image} alt="" />
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <h2 className="title_sub">{t("about.subdivision")}</h2>
      <div className="menegBtn d-flex align-items-center justify-content-between">
        <button onClick={() => filterElements("acc")}>
          {t("jobs.menegBtn.accountant")}
        </button>
        <button onClick={() => filterElements("eco")}>
          {t("jobs.menegBtn.economist")}
        </button>
        <button onClick={() => filterElements("ins")}>
          {t("jobs.menegBtn.personnelInspector")}
        </button>
        <button onClick={() => filterElements("gui")}>
          {t("jobs.menegBtn.bureauGUIs")}
        </button>
        <button onClick={() => filterElements("cad")}>
          {t("jobs.menegBtn.CADgroup")}
        </button>
        <button onClick={() => filterElements("okp")}>
          {t("jobs.menegBtn.OKPdepartment")}
        </button>
        <button onClick={() => filterElements("opi")}>
          {t("jobs.menegBtn.departmentOfOP&I")}
        </button>
        <button onClick={() => filterElements("sec")}>
          {t("jobs.menegBtn.securityCommunications")}
        </button>
        <button onClick={() => filterElements("cmo")}>
          {t("jobs.menegBtn.CMOdepartment")}
        </button>
        <button onClick={() => filterElements("ovp")}>
          {t("jobs.menegBtn.OVPdepartment")}
        </button>
        <button onClick={() => filterElements("nti")}>
          {t("jobs.menegBtn.NTIGroup")}
        </button>
      </div>
      <div>
        <div className="">
          {data?.map((el, index) => {
            return (
              <div className="">
                <CardManagement el={el} key={index} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
export default Management;
