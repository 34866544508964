import React, { useContext } from "react";
import { Context } from "../../Context";
import "./links.scss";
import { BsFillPatchCheckFill } from "react-icons/bs";

function Links() {
  const { change } = useContext(Context);
  const data = [
    {
      link: "https://president.uz/ru/",
      name: [
        "Пресс-служба Президента Республики Узбекистан",
        "Press Service of the President of the Republic of Uzbekistan",
        "O‘zbekiston Respublikasi Prezidentining Matbuot xizmati",
      ],
    },
    {
      link: "https://sovetgt.org/",
      name: [
        "Совет по железнодорожному транспорту",
        "Rail Transport Council",
        "Temir yo'l transporti kengashi",
      ],
    },
    {
      link: "https://parliament.gov.uz/",
      name: [
        "Законодательная палата Олий Мажлиса Республики Узбекистан",
        "Legislative Chamber of the Oliy Majlis of the Republic of Uzbekistan",
        "O‘zbekiston Respublikasi Oliy Majlisining Qonunchilik palatasi",
      ],
    },
    {
      link: "https://www.gov.uz/ru/",
      name: [
        "Правительственный портал Республики Узбекистан",
        "Government portal of the Republic of Uzbekistan",
        "O'zbekiston Respublikasi Hukumat portali",
      ],
    },
    {
      link: "",
      name: [
        "Организация сотрудничества железных дорог",
        "Organization for cooperation between railways",
        "Temir yo'llar o'rtasidagi hamkorlik tashkiloti",
      ],
    },
    {
      link: "",
      name: [
        "Министерства по развитию информационных технологий и коммуникаций Республики Узбекистан",
        "Ministry for the Development of Information Technologies and Communications of the Republic of Uzbekistan",
        "O‘zbekiston Respublikasi Axborot texnologiyalari va kommunikatsiyalarini rivojlantirish vazirligi",
      ],
    },
    {
      link: "https://regulation.gov.uz/oz",
      name: [
        "Обсуждение нормативно-правовых актов Республики Узбекистан",
        "Discussion of legal acts of the Republic of Uzbekistan",
        "O'zbekiston Respublikasi qonun hujjatlarini muhokama qilish",
      ],
    },
    {
      link: "https://my.gov.uz/ru",
      name: [
        "Единый портал интерактивных государственных услуг",
        "Single portal of interactive public services",
        "Yagona interaktiv davlat xizmatlari portali",
      ],
    },
  ];
  return (
    <div className="container ">
      <div className="row Links">
        {data.map((el) => {
          return (
            <div className="col-lg-3 Link">
              <h5 className="icon">
                <BsFillPatchCheckFill />
              </h5>
              <a href={el.link}>
                <h4>{el.name[change]}</h4>
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Links;
