import React, { useContext } from "react";
import "./Cards.scss";

// impost images
import CardsPhoto from "../../assets/images/cards.jpg";
import Cards2 from "../../assets/images/cards2.jpg";
import Cards3 from "../../assets/images/cards3.jpg";
import Cards4 from "../../assets/images/cards4.jpg";
import Cards5 from "../../assets/images/cards5.jpg";
import Cards6 from "../../assets/images/cards6.jpg";

// import redux assets
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import { useDispatch, useSelector } from "react-redux";
import { getAllElements } from "../../Redux/Features/productSlice";

//import Contexts
import { Context } from "../../Context";

// import components
import CardsInfo from "./CardsInfo";

function Cards() {
  const CardsData = [
    {
      name: [
        "Проектирование пути и изысканий",
        "Path and survey design",
        "Yo'l va tadqiqot dizayni",
      ],
      title: [
        "тахеометрические съёмки в масштабах 1:2000-1:500 съёмка существующих железнодорожных станций, узлов и пересечение железных дорог с автодорогами исъёмка полотна железных дорог на перегонах;тахеометрическая съёмка внутризаводских железных дорог .",
        "tacheometric surveys on a scale of 1:2000-1:500 survey of existing railway stations, junctions and intersection of railways with roads and surveying the railroad tracks on hauls; tacheometric survey of intra-factory railways",
        "taxeometricheskie s'yomki v masshtabax 1:2000-1:500 s'yomka sushchestvuyushchix jeleznodorojnyx stansiyasi, uzlov va pereschenie jeleznyx dorog avtodorogami is'yomka polotna jeleznyx dorog s'yomka va peregonah;",
      ],
      image: CardsPhoto,
    },
    {
      name: [
        "Система автоматизации проектных работ",
        "Design work automation system",
        "Loyihalash ishlarini avtomatlashtirish tizimi",
      ],
      title: [
        "Основные задачи группы Система Автоматизации Проектных Работ (САПР): В рамках жизненного цикла промышленных изделий САПР решает задачи автоматизации работ на стадиях проектирования и подготовки производства. Основная цель создания САПР — повышение эффективности труда инженеров, включая:",
        "The main tasks of the Design Works Automation System (CAD) group: As part of the life cycle of industrial products, CAD solves the tasks of automating work at the stages of design and pre-production. The main goal of creating a CAD system is to increase the efficiency of the work of engineers, including:",
        "Loyihalash ishlarini avtomatlashtirish tizimi (SAPR) guruhining asosiy vazifalari: Sanoat mahsulotlarining hayot aylanishining bir qismi sifatida SAPR loyihalash va ishlab chiqarishdan oldingi bosqichlarda ishlarni avtomatlashtirish vazifalarini hal qiladi. SAPR tizimini yaratishning asosiy maqsadi muhandislar ishining samaradorligini oshirish, shu jumladan:",
      ],
      image: Cards2,
    },
    {
      name: [
        "Комплексное проектирование",
        "Integrated design",
        "Integratsiyalashgan dizayn",
      ],
      title: [
        "Основные задачи Отдела Комплексного Проектирования (ОКП): проектирование застройки жилых поселков в составе комплексных проектов строительства, реконструкции объектов железнодорожного транспорта;",
        "The main tasks of the Department of Integrated Design (OKD): designing the development of residential settlements as part of complex construction projects, reconstruction of railway transport facilities;",
        "Integratsiyalashgan dizayn kafedrasining (OKD) asosiy vazifalari:kompleks qurilish loyihalari doirasida aholi punktlarini rivojlantirishni loyihalash, temir yo'l transporti ob'ektlarini rekonstruksiya qilish;",
      ],
      image: Cards3,
    },
    {
      name: ["Выпуск проектов", "Release projects", "Loyihalarni chiqarish"],
      title: [
        "ОСНОВНЫЕ ЗАДАЧИ ОТДЕЛА ВЫПУСКА ПРОЕКТОВ: выполнение копирования, тиражирования, оформления, комплектацию и отправку заказчикам, а так же передачу в технический архив разработанной проектно-сметной документации;",
        "MAIN OBJECTIVES OF THE PROJECT RELEASE DEPARTMENT: copying, replicating, designing, completing and sending to customers, as well as transferring the developed design and estimate documentation to the technical archive;",
        "LOYIHALARNI BERISH BO‘LIMINING ASOSIY MAQSADLARI: nusxa ko‘chirish, takrorlash, loyihalash, to‘ldirish va buyurtmachilarga jo‘natish, shuningdek ishlab chiqilgan loyiha-smeta hujjatlarini texnik arxivga topshirish;",
      ],
      image: Cards4,
    },
    {
      name: [
        "Выпуск смет",
        "Release of estimates",
        "Hisob-kitoblarni chiqarish",
      ],
      title: [
        "ОСНОВНЫЕ ЗАДАЧИ СМЕТНОГО ОТДЕЛА:составление в соответствии с нормативными требованиями сметной документации, спецификаций и других документов в зависимости от стадии проектирования;",
        "MAIN OBJECTIVES OF THE ESTIMATE DEPARTMENT: preparation of estimate documentation, specifications and other documents in accordance with regulatory requirements, depending on the design stage;",
        "SMETA BO'LIMINING ASOSIY MAQSADLARI: loyihalash bosqichiga qarab normativ talablarga muvofiq smeta hujjatlari, texnik shartlar va boshqa hujjatlarni tayyorlash;",
      ],
      image: Cards5,
    },
    {
      name: [
        "Связь, сигнализация, централизация и блокировка",
        "Communication, signaling, centralization and blocking",
        "Aloqa, signalizatsiya, markazlashtirish va blokirovka qilish",
      ],
      title: [
        "Основные задачи ОтделаСигнализации, Централизации и Блокировки (СЦБ И СВЯЗЬ) проектирование электрической и микропроцессорной централизации (ЭЦ и МПЦ) на станциях проектирование электрической централизации стрелок;",
        "The main tasks of the Department of Signaling, Centralization and Blocking (STSB AND COMMUNICATION) design of electrical and microprocessor interlocking (EC and MPC) at stations; design of electrical interlocking of switches;",
        "Signalizatsiya, markazlashtirish va blokirovkalash (STSB VA ALOQA) bo'limining asosiy vazifalari stantsiyalarda elektr va mikroprotsessor blokirovkasini (EC va MPC) loyihalash; kalitlarning elektr blokirovkasini loyihalash;",
      ],
      image: Cards6,
    },
  ];
  const elements = useSelector(getAllElements);
  const dispatch = useDispatch();
  console.log(elements);
  const { change } = useContext(Context);

  return (
    <section id="Cards">
      <div className="d-flex justify-content-center align-items-center">
        <ScrollingCarousel
          className="sliiCar"
          show={4.5}
          slide={2}
          transition={0.5}
        >
          {CardsData?.map((el, index) => {
            return <CardsInfo change={change} el={el} />;
          })}
        </ScrollingCarousel>
      </div>
    </section>
  );
}
export default Cards;
