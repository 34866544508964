import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./components/pages/Home";
import Footer from "./components/Footer/Footer";
import About from "./components/pages/About";
import Navbar from "./components/Navbar/Navbar";
import Detail from "./components/Cards/Detail";
import Projects from "./components/pages/Projects/Projects";
import Jobs from "./components/Jobs/Jobs";
import Contact from "./components/Contact/Contact";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  getData,
  getProjects,
  getVacanse,
} from "./Redux/Features/productSlice";
import { getManager } from "./Redux/Features/productSlice";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getData());
  }, []);

  useEffect(() => {
    dispatch(getVacanse());
  });

  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/project" element={<Projects />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/jobs" element={<Jobs />} />
        <Route path="/detail/:id" element={<Detail />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
