import React, { useContext } from "react";
import { Context } from "../../../../Context";
import "./StyleContent.scss";
function CompletedProjects({ el, index }) {
  const { change } = useContext(Context);
  console.log(el);
  const item = el.attributes;
  if (item.category === true) {
    const UPLOAD_IMAGE_API = "http://localhost:1337";
    return (
      <div className="projectsImg">
        <img
          src={UPLOAD_IMAGE_API + el.attributes?.image.data.attributes.url}
          alt=""
        />
        <ul>
          <li>{item.title[change]}</li>
        </ul>
      </div>
    );
  } else {
    console.log("planned project errorr !!!!!!!!!!!!!");
  }
}
export default CompletedProjects;
