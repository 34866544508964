import React, { useContext } from "react";
import { Context } from "../../../Context";

import { formatPrice } from "../../../helper";

function CardManagement({ el, index }) {
  const { change } = useContext(Context);
  const UPLOAD_IMAGE_API = "http://localhost:1337";
  const item = el.attributes;

  return (
    <div key={index} className="d-flex">
      <div className="cardManager d-flex align-items-center justify-content-around">
        <div>
          <h4>{item.name[change]}</h4>
          <h4>{item.job[change]}</h4>
          <h4>{formatPrice(item.phone)}</h4>
          <h4>{item.email}</h4>
        </div>
        <div>
          <img
            src={UPLOAD_IMAGE_API + el.attributes?.image.data.attributes.url}
            alt=""
          />
        </div>
      </div>
    </div>
  );
}
export default CardManagement;
