import React, { useState } from "react";
import "./styles/License.scss";
import license from "../../../assets/images/license/license.jpg";
import license1 from "../../../assets/images/license/license1.jpg";
import license2 from "../../../assets/images/license/license2.jpg";
import license3 from "../../../assets/images/license/license3.jpg";
import license4 from "../../../assets/images/license/license4.jpg";
import license5 from "../../../assets/images/license/license5.jpg";
import license6 from "../../../assets/images/license/license6.jpg";
import license7 from "../../../assets/images/license/license7.jpg";
import license8 from "../../../assets/images/license/license8.jpg";
import license9 from "../../../assets/images/license/license9.jpg";
import license10 from "../../../assets/images/license/license10.jpg";

const licenseData = [
  {
    id: 1,
    image: license10,
  },
  {
    id: 2,
    image: license,
  },
  {
    id: 3,
    image: license9,
  },
  {
    id: 4,
    image: license1,
  },
  {
    id: 5,
    image: license4,
  },
  {
    id: 6,
    image: license5,
  },
  {
    id: 7,
    image: license6,
  },
  {
    id: 8,
    image: license7,
  },
  {
    id: 9,
    image: license8,
  },
  {
    id: 10,
    image: license2,
  },
  {
    id: 11,
    image: license3,
  },
];
const License = () => {
  return (
    <section id="licens">
      <div className="row">
        {licenseData.map((el) => {
          return (
            <div className="licenseImg col-lg-4 col-md-6 col-sm-12 d-flex">
              <img src={el.image} alt="" />
            </div>
          );
        })}
      </div>
    </section>
  );
};
export default License;
