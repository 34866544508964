import { t } from "i18next";
import "./styles/History.scss";
function History() {
  return (
    <section id="History">
      <div className="container">
        <h4 id="top">{t("aboutHistory.title")}</h4>
        <div>
          <ul>
            <li>{t("aboutHistory.titleSub")}</li>
            <li>{t("aboutHistory.titleSub1")}</li>
            <li>{t("aboutHistory.titleSub2")}</li>
            <li>{t("aboutHistory.titleSub3")}</li>
            <li>{t("aboutHistory.titleSub4")}</li>
            <li>{t("aboutHistory.titleSub5")}</li>
            <li>{t("aboutHistory.titleSub6")}</li>
            <li>{t("aboutHistory.titleSub7")}</li>
          </ul>
        </div>
        <div>
          <p>{t("aboutHistory.titleSub8")}</p>
        </div>
        <div>
          <h4>{t("historyOfLLC.title")}</h4>
        </div>
        <div>
          <p>{t("historyOfLLC.titleSub")}</p>
          <p>{t("historyOfLLC.titleSub1")}</p>
          <p>{t("historyOfLLC.titleSub2")}</p>
          <p>{t("historyOfLLC.titleSub3")}</p>
          <p>{t("historyOfLLC.titleSub4")}</p>
          <p>{t("historyOfLLC.titleSub5")}</p>
        </div>
        <div>
          <h4>{t("strengtheningTransport.title")}</h4>
        </div>
        <div>
          <ul>
            <li>
              {t("strengtheningTransport.strengtheningTransportTasks.Tasks")}
            </li>
            <li>
              {t("strengtheningTransport.strengtheningTransportTasks.Tasks1")}
            </li>
            <li>
              {t("strengtheningTransport.strengtheningTransportTasks.Tasks2")}
            </li>
          </ul>
        </div>
        <div>
          <p>{t("strengtheningTransport.text.text1")}</p>
          <p>{t("strengtheningTransport.text.text2")}</p>
          <p>{t("strengtheningTransport.text.text3")}</p>
          <p>{t("strengtheningTransport.text.text4")}</p>
        </div>
        <ul>
          <li>
            {t("strengtheningTransport.strengtheningTransportTasks1.Tasks")}
          </li>
          <li>
            {t("strengtheningTransport.strengtheningTransportTasks1.Tasks1")}
          </li>
          <li>
            {t("strengtheningTransport.strengtheningTransportTasks1.Tasks2")}
          </li>
        </ul>
        <div>
          <h4>{t("projects.title")}</h4>
        </div>
        <ul>
          <li>{t("projects.madeProjects.project")}</li>
          <li>{t("projects.madeProjects.project1")}</li>
          <li>{t("projects.madeProjects.project2")}</li>
          <li>{t("projects.madeProjects.project3")}</li>
          <li>{t("projects.madeProjects.project4")}</li>
          <li>{t("projects.madeProjects.project5")}</li>
          <li>{t("projects.madeProjects.project6")}</li>
          <li>{t("projects.madeProjects.project7")}</li>
          <li>{t("projects.madeProjects.project8")}</li>
          <li>{t("projects.madeProjects.project9")}</li>
          <li>{t("projects.madeProjects.project10")}</li>
          <li>{t("projects.madeProjects.project11")}</li>
          <li>{t("projects.madeProjects.project12")}</li>
          <li>{t("projects.madeProjects.project13")}</li>
          <li>{t("projects.madeProjects.project14")}</li>
          <li>{t("projects.madeProjects.project15")}</li>
          <li>{t("projects.madeProjects.project16")}</li>
          <li>{t("projects.madeProjects.project17")}</li>
          <li>{t("projects.madeProjects.project18")}</li>
          <li>{t("projects.madeProjects.project19")}</li>
          <li>{t("projects.madeProjects.project20")}</li>
          <li>{t("projects.madeProjects.project21")}</li>
          <li>{t("projects.madeProjects.project22")}</li>
          <li>{t("projects.madeProjects.project23")}</li>
          <li>{t("projects.madeProjects.project24")}</li>
          <li>{t("projects.madeProjects.project25")}</li>
          <li>{t("projects.madeProjects.project26")}</li>
          <li>{t("projects.madeProjects.project27")}</li>
          <li>{t("projects.madeProjects.project28")}</li>
        </ul>
        <div>
          <h4>{t("develop.title")}</h4>
        </div>
        <ul>
          <li>{t("develop.beingDeveloped.development")}</li>
          <li>{t("develop.beingDeveloped.development1")}</li>
          <li>{t("develop.beingDeveloped.development2")}</li>
          <li>{t("develop.beingDeveloped.development3")}</li>
          <li>{t("develop.beingDeveloped.development4")}</li>
          <li>{t("develop.beingDeveloped.development5")}</li>
          <li>{t("develop.beingDeveloped.development6")}</li>
          <li>{t("develop.beingDeveloped.development7")}</li>
        </ul>
        <div>
          <h3>{t("PASSPORT.title.name")}</h3>
          <h3>{t("PASSPORT.title.passport")}</h3>
          <h3>{t("PASSPORT.title.name1")}</h3>
        </div>
        <div className="container">
      <table className="table">
        <thead>
          <tr>
          <th>№</th>
              <th className="name">{t("table.NAME")}</th>
              <th>{t("table.INTELLIGENCE")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
          <td>1</td>
              <td className="name">{t("table.nameOfCompany.name")}</td>
              <td>{t("table.nameOfCompany.title")}</td>
          </tr>
          <tr>
          <td>2</td>
              <td className="name">{t("table.organizationAddress.name")}</td>
              <td>{t("table.organizationAddress.title")}</td>
          </tr>
          <tr>
          <td>3</td>
              <td className="name">{t("table.organized.name")}</td>
              <td>{t("table.organized.title")}</td>
          </tr>
          <tr>
          <td>4</td>
              <td className="name">{t("table.FormEnterprise.name")}</td>
              <td>{t("table.FormEnterprise.title")}</td>
          </tr>
          <tr>
          <td>5</td>
              <td className="name">
                <span>{t("table.mainOrganization.name")}</span>
              </td>
              <td>{t("table.mainOrganization.title")}</td>
          </tr>
        </tbody>
      </table>
    </div>
       
        <table className="table2 d-flex flex-column ">
          <tbody>
            <tr className="weight">{t("table.nameOfCompany.name")}</tr>
            <tr>{t("table.nameOfCompany.title")}</tr>
            <tr className="weight">{t("table.organizationAddress.name")}</tr>
            <tr>{t("table.organizationAddress.title")}</tr>

            <tr className="weight">{t("table.organized.name")}</tr>
            <tr>{t("table.organized.title")}</tr>

            <tr className="weight">{t("table.FormEnterprise.name")}</tr>
            <tr>{t("table.FormEnterprise.title")}</tr>

            <tr className="weight">{t("table.mainOrganization.name")}</tr>
            <tr>{t("table.mainOrganization.title")}</tr>
          </tbody>
        </table>
      </div>
    </section>
  );
}
export default History;
