import React from "react";

function CardsInfo({ el, change }) {
  // const url = el.attributes.image.data.attributes.url;
  // const UPLOAD_IMAGE_API = "http://localhost:1337";
  return (
    <div className="Card">
      <div>
        <img src={el.image} alt="" />
        <div className="cards-title">
          <h5>{el.name[change]}</h5>
          <p>{el.title[change]}</p>
        </div>
      </div>
    </div>
  );
}

export default CardsInfo;
