import "./Projects.scss";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { t } from "i18next";
import PlannedProjects from "./ProjectsContent/PlannedProjects";
import CompletedProjects from "./ProjectsContent/CompletedProjects";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllProjects,
  getProjects,
} from "../../../Redux/Features/productSlice";
import { useEffect, useState } from "react";
function Projects() {
  const [cat, setCat] = useState("planned");
  const projects = useSelector(getAllProjects);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProjects(cat));
  }, [cat]);
  console.log(projects);

  return (
    <section id="projects">
      <Tabs>
        <TabList>
          <div className="container">
            <Tab>{t("projectsSection.tabsPanel.projectedPprojects")}</Tab>
            <Tab>{t("projectsSection.tabsPanel.completedProjects")}</Tab>
          </div>
        </TabList>
        <div className="container">
          <TabPanel>
            <section id="project_">
              <h3 className="">{t("projectsSection.projectsTitles.title")}</h3>
              {projects?.map((el) => {
                return <PlannedProjects el={el} />;
              })}
            </section>
          </TabPanel>
          <TabPanel>
            <section id="project_">
              <h3 className="">{t("projectsSection.projectsTitles.title1")}</h3>
              {projects?.map((el) => {
                return <CompletedProjects el={el} />;
              })}
            </section>
          </TabPanel>
        </div>
      </Tabs>
    </section>
  );
}
export default Projects;
