import "./Contact.scss";
import { BsTelephoneFill } from "react-icons/bs";
import { CgMail } from "react-icons/cg";
import { AiOutlineHome } from "react-icons/ai";
import { TbPhoneCall } from "react-icons/tb";
import { t } from "i18next";
function Contact() {
  return (
    <section id="contact">
      <div className="container">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <div className="d-flex flex-column align-items-start justify-content-center">
              <div className="contactIcon d-flex align-items-stretch ">
                <AiOutlineHome />
                <p>{t("table.organizationAddress.title")}</p>
              </div>
              <div className="contactIcon d-flex align-items-stretch">
                <TbPhoneCall />
                <p>(+99871) 237-92-72 (+99871) 256-50-18</p>
              </div>
              <div className="contactIcon d-flex align-items-stretch">
                <CgMail />
                <p>temiryol@gmail.com</p>
              </div>
              <div className="contactIcon d-flex align-items-stretch">
                <BsTelephoneFill />
                <p>Ж.Д 4-92-72</p>
              </div>
            </div>
          </div>
          {/* <div className="str"></div> */}
        </div>
        <iframe
          src="https://yandex.ru/map-widget/v1/?um=constructor%3Afb19c86a3b46bdb7e9dd90e05511b47ecc39e9defa31f31f750cf54e6b225d00&amp;source=constructor"
          width="100%"
          height="500"
          frameBorder="0"
          title="w"
        ></iframe>
      </div>
    </section>
  );
}
export default Contact;
