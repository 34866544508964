import i18n from "i18next";
import Backend from "i18next-http-backend";
import LangugeDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n.use(Backend).use(LangugeDetector).use(initReactI18next).init({
  fallbacking: "ru",
  debug: true,
  // detection: {
  //   order: ["querySrting", "cookie"],
  //   cache: ["cookie"],
  // },
});
// i18n.use(Backend).use(LangugeDetector).use(initReactI18next).init({
//   fallbacking: "ru",
//   debug: true,
//   // detection: {
//   //   order: ["querySrting", "cookie"],
//   //   cahce: ["cookie"],
//   // },
//   // interpolation: {
//   //   escapeValue: false,
//   // },
// });
// export default i18n;
