function Detail(){
    return(
        <div className="Card">
        <div>
          <img src="" alt="" />
          <div className="cards-title">
            <h5>dsa</h5>
            <p>dasd</p>
          </div>
        </div>
      </div>
    )
}
export default Detail