import React, {createContext , useState , useEffect} from 'react'


const Context = createContext()
function ContextProvider({children}) {


    const side = localStorage.getItem("sidebar")
    ? JSON.parse(localStorage.getItem("sidebar"))
    : [];

    const [change , setChange] = useState(side)
    
    useEffect(() => {
        localStorage.setItem("sidebar", JSON.stringify(change));
      }, [change]);

  return (
    <div>
        
        <Context.Provider value={{change , setChange}}>
            {children}
        </Context.Provider>
      
    </div>
  )
}

export {Context , ContextProvider}
