import Cards from "../Cards/Cards";
import Header from "../Header/Header";
import Services from "../Services/Services";
import Links from "../Links/Links";
import "./styles/Home.scss";
function Home() {
  return (
    <div>
      <Header />
      <Cards />
      <Services />
      <Links />
    </div>
  );
}
export default Home;
