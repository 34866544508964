import "./Footer.scss";
import Logo from "../../assets/icons/logoru.png";
import { SiTelegram } from "react-icons/si";
import { BsFacebook, BsInstagram } from "react-icons/bs";
import { t } from "i18next";
function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="content-location d-flex justify-content-between">
          <div className="d-flex flex-column justify-content-around">
            <div className="logo">
              <img src={Logo} alt="" />
            </div>
            <div className="">
              <h6>{t("table.organizationAddress.title")}</h6>
              <h6>
                <a href="mailto:temiryol@gmail.com">temiryol@gmail.com </a>{" "}
              </h6>
              <h6>Тел: (+99871) 237-92-72</h6>
              <h6>Факс: (+99871) 256-50-18</h6>
            </div>
            <div className="link_icons d-flex  ">
              <a
                targer="_blank"
                rel="noopener noreferrer"
                href="https://t.me/+998909190681"
              >
                <SiTelegram />
              </a>
              <a
                targer="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/ttyl.mchj.1/"
              >
                <BsFacebook />
              </a>
              <a
                targer="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/toshtemiryolloyiha_mchj/"
              >
                <BsInstagram />
              </a>
            </div>
          </div>

          <div className="map">
            <iframe
              title="map"
              src="https://yandex.ru/map-widget/v1/?um=constructor%3Ac86557d04f1b5861239b082cb0d61ecf9f497754a66d546234a222a837d184a4&amp;source=constructor"
              width="400"
              height="250"
              frameBorder={"0"}
            ></iframe>
          </div>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
