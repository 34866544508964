import "./Loading.scss";

function Loading() {
  return (
    <div className="loading">
      <div classname="spinner"></div>
    </div>
  );
}
export default Loading;
