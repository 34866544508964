import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAllVacanse } from "../../Redux/Features/productSlice";
import "./Jobs.scss";
import Loading from "../Loading/Loading";
function Jobs() {
  const vacanse = useSelector(getAllVacanse);
  const dispatch = useDispatch();
  console.log(vacanse);
  if (vacanse === []) {
    return (
      <div className="loading">
        <Loading />
      </div>
    );
  }
  return (
    <section id="Jobs">
      <div className="container">
        <h3>{t("jobs.title")}</h3>
        <div className="jobsCards d-flex">
          {vacanse?.map((el, index) => {
            console.log(el);
            const item = el.attributes;
            return (
              <div key={index} className="jobsCard">
                <div>
                  {/* <h6>{item.name[0]}</h6>
                  <div>
                    <p className="weight">Требования:</p>
                    {el.requirement?.map((item, index) => {
                      return (
                        <div key={index}>
                          <p>— {item}</p>
                        </div>
                      );
                    })}
                  </div> */}
                  <div>
                    <p className="weight">Обязанности:</p>
                    {el.responsibilities?.map((item, index) => {
                      return (
                        <div key={index}>
                          <p>— {item}</p>
                        </div>
                      );
                    })}
                  </div>
                  <div>
                    <p className="weight">Условия работы:</p>
                    {item.description?.map((item, index) => {
                      return (
                        <div key={index}>
                          <p>— {item}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
export default Jobs;
//      const [readMore, setReadMore] = useState(false);

// {readMore ? info : `${info.substring(0, 200)}...`}
// <button onClick={() => setReadMore(!readMore)}>
//   {readMore ? 'show less' : '  read more'}
// </button>
