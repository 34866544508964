import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";
const initialState = {
  data: [],
  management: [],
  projects: [],
  vacanse: [],
  status: "idle",
};
export const getData = createAsyncThunk("axios get elements", async () => {
  const response = axios.get(`http://localhost:1337/api/products?populate=*`);
  return response;
});

export const getVacanse = createAsyncThunk("axios get vacanse", async () => {
  const response = axios.get(`http://localhost:1337/api/vacanses?populate=*`);
  return response;
});

export const getManager = createAsyncThunk(
  "axios get  management",
  async () => {
    const response = axios.get(
      `http://localhost:1337/api/managements?populate=*`
    );
    return response;
  }
);
export const getProjects = createAsyncThunk("axios get projects", async () => {
  const response = axios.get(`http://localhost:1337/api/projects?populate=*`);
  return response;
});
const productSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    getElement: {
      reducers: (state, action) => {
        console.log(action.payload);
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getData.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload.data.data;
        console.log(action.payload.data);
      })
      .addCase(getData.rejected, (state, action) => {
        state.status = "rejected";
      })

      .addCase(getManager.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(getManager.fulfilled, (state, action) => {
        state.status = "success";
        state.management = action.payload.data.data;
      })
      .addCase(getManager.rejected, (state, action) => {
        state.status = "rejected";
      })

      .addCase(getProjects.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(getProjects.fulfilled, (state, action) => {
        state.status = "success";
        state.projects = action.payload.data.data;
      })
      .addCase(getProjects.rejected, (state, action) => {
        state.status = "rejected";
      })
      .addCase(getVacanse.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(getVacanse.fulfilled, (state, action) => {
        state.status = "success";
        state.vacanse = action.payload.data.data;
        console.log(action.payload.data.data);
      })
      .addCase(getVacanse.rejected, (state, action) => {
        state.status = "rejected";
      });
  },
});

export const getAllVacanse = (state) => state.product.vacanse;
export const getAllProjects = (state) => state.product.projects;
export const getAllManangement = (state) => state.product.management;
export const getAllElements = (state) => state.product.data;
export default productSlice;
