import React , {useContext} from "react";
import "./Navbar.scss";
import { useTranslation } from "react-i18next";
import Logo from "../../assets/icons/logoru.png";
import { SlMenu } from "react-icons/sl";
import { Link } from "react-router-dom";
import { TfiClose } from "react-icons/tfi";
import { useState } from "react";
import { Context } from "../../Context";
function Navbar() {
  const refresh = () => window.location.reload(true);
  const { t, i18n } = useTranslation();
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  
  const {change , setChange} = useContext(Context)
  console.log(change);

  const [menu, setMenu] = useState(false);
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <nav className="Navbar">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between">
            <div className="logo">
              <Link to="/">
                <img src={Logo} alt="" />
              </Link>
            </div>
            <div className="nav-li">
              <div className="d-flex align-items-center">
                <ul
                  className="list d-flex align-items-center"
                  onClick={() => {
                    goToTop();
                  }}
                >
                  <li>
                    <Link to="/" className="link">
                      {t("navbar.main")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/about" className="link">
                      {t("navbar.about")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/project" className="link">
                      {t("navbar.projects")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/jobs" className="link">
                      {t("navbar.vacancies")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact" className="link">
                      {t("navbar.contacts")}
                    </Link>
                  </li>
                </ul>
                <div
                  className="language-btn d-flex align-items-center justify-content-between align-items-center"
                  onClick={refresh}
                >
                  <a onClick={() => setChange(0)}>
                  <button onClick={() => changeLanguage("ru")}>ru</button>
                  </a>
                  <div>|</div>
                  <a onClick={() => setChange(1)}>
                  <button onClick={() => changeLanguage("en")}>en</button>
                  </a>
                  <div>|</div>
                  <a onClick={() => setChange(2)}>
                  <button onClick={() => changeLanguage("uz")}>uz</button>
                  </a>
                </div>
              </div>
            </div>
            <div className="nav-hamburger-menu">
              {menu ? (
                <TfiClose onClick={() => setMenu(false)} className="TfiClose" />
              ) : (
                <SlMenu onClick={() => setMenu(true)} className="SlMenu" />
              )}
              <div></div>
            </div>
          </div>
          <div className="myAnim">
            <div className={`main-collapse ${menu ? "hamburger-menu" : ""} `}>
              <div
                className="d-flex flex-column align-items-center"
                onClick={() => {
                  goToTop();
                }}
              >
                <ul
                  onClick={() => setMenu(false)}
                  className="d-flex flex-column align-items-center"
                >
                  <li>
                    <Link to="/">{t("navbar.main")}</Link>
                  </li>
                  <li>
                    <Link to="/about">{t("navbar.about")}</Link>
                  </li>
                  <li>
                    <Link to="/project">{t("navbar.projects")}</Link>
                  </li>
                  <li>
                    <Link to="/jobs">{t("navbar.vacancies")}</Link>
                  </li>
                  <li>
                    <Link to="/contact">{t("navbar.contacts")}</Link>
                  </li>
                </ul>
                <div
                  className="language-btn d-flex align-items-center"
                  onClick={refresh}
                >
                  <button onClick={() => changeLanguage("ru")}>ru</button>
                  <div>|</div>
                  <button onClick={() => changeLanguage("en")}>en</button>
                  <div>|</div>
                  <button onClick={() => changeLanguage("uz")}>uz</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div></div>
      </nav>
    </div>
  );
}
export default Navbar;
