import { t } from "i18next";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import History from "./AboutContent/History";
import License from "./AboutContent/License";
import Management from "./AboutContent/Management";
import Structure from "./AboutContent/Structure";
function About() {
  return (
    <section className="show">
      <Tabs className="tabs">
        <TabList>
          <div className="container">
            <Tab>{t("about.history")}</Tab>
            <Tab>{t("about.structure")}</Tab>
            <Tab>{t("about.management")}</Tab>
            <Tab>{t("about.license")}</Tab>
          </div>
        </TabList>
        <div className="container">
          <TabPanel>
            <History />
          </TabPanel>
          <TabPanel>
            <Structure />
          </TabPanel>
          <TabPanel>
            <Management />
          </TabPanel>
          <TabPanel>
            <License/>
          </TabPanel>
        </div>
      </Tabs>
    </section>
  );
}
export default About;
